@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

@font-face {
  font-family: SegoeUI;
  font-weight: 400;
  src: local('Segoe UI'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2') format('woff2'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff') format('woff'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf') format('truetype'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.svg#web') format('svg');
}

@font-face {
  font-family: SegoeUILight;
  font-weight: 100;
  src: local('Segoe UI Light'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2') format('woff2'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff') format('woff'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf') format('truetype'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.svg#web') format('svg');
}

@font-face {
  font-family: SegoeUISemilight;
  font-weight: 200;
  src: local('Segoe UI Semilight'), local('Segoe UI'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2') format('woff2'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff') format('woff'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf') format('truetype'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.svg#web') format('svg');
}

@font-face {
  font-family: SegoeUISemibold;
  font-weight: 600;
  src: local('Segoe UI Semibold'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2') format('woff2'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff') format('woff'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf') format('truetype'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.svg#web') format('svg');
}

@font-face {
  font-family: SegoeUIBold;
  font-weight: 700;
  src: local('Segoe UI Bold'), local('Segoe UI'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2') format('woff2'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff') format('woff'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf') format('truetype'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.svg#web') format('svg');
}
@font-face {
  font-family: SegoeUIBlack;
  font-weight: 900;
  src: local('Segoe UI Black'), local('Segoe UI'), url('./assets/fonts/segoeBlack.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  font-family: 'SegoeUI';
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'SegoeUI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  height: 100vh;
  width: 100vw;
}
