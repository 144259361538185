/* stylelint-disable */
* {
  &::-webkit-scrollbar {
    width: 0px;
    opacity: 0;
  }
}

.portal {
  position: relative;
}

.click-target {
  height: 4%;
  position: absolute;
  width: 8%;
}

.click-target-guided {
  background: radial-gradient(circle, transparent 26%, #0078d4 0%, transparent 100%);
  border-radius: 150px;
  cursor: pointer;
  height: 9%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 5%;
}

.square-click-box-container {
  background: transparent;
  border-radius: 0%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 20px;
  width: 100px;
  transform: scale(1);
  animation: pulse-green 2s infinite;
  position: absolute;
  border-radius: 3px;
  cursor: pointer;
}

.pulsing-target {
  z-index: 10000;
  &.hidden {
    pointer-events: none;
    visibility: hidden;
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(1);
    box-shadow: 0 0 20px 20px rgba(5, 90, 209, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 20px 24px rgba(5, 90, 209, 0.8);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 20px 20px rgba(5, 90, 209, 0.8);
  }
}

@keyframes clickPoint {
  0% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 60%);
    opacity: 1;
  }

  10% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 60%);
  }

  20% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 60%);
  }

  30% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 65%);
  }

  40% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 70%);
  }

  50% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 75%);
  }

  60% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 80%);
  }

  70% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 85%);
  }

  80% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 90%);
  }

  90% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 95%);
  }

  100% {
    background: radial-gradient(circle, transparent 24%, #0078d4 26%, transparent 100%);
    opacity: 1;
  }
}

.focus {
  opacity: 0;
  position: absolute;
}

.ffwd-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 12vh;
  right: 2vw;
}

.ffwd-icon {
  width: 3.6vw;
  height: 3.6vw;
}

.user-info {
  position: absolute;
  right: 0;
  top: 0.44vw;
  color: white;
  display: flex;
  font-size: 0.8vw;
  justify-content: flex-end;
  min-width: 8.3vw;
  user-select: none;

  > div {
    display: flex;
    flex-flow: column;
    text-align: right;
  }

  img {
    height: 2vw;
    width: 2vw;
    object-fit: cover;
    margin-right: 0.3vw;
    margin-left: 0.4vw;
    border-radius: 50%;
  }

  span {
    display: block;
    line-height: 1;
    width: 100%;
    text-align: right;

    &:last-child {
      margin-top: 2px;
      font-size: 0.7vw;
    }
  }
}
